import React, { useEffect, useState, useContext, memo } from 'react';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid';
import { PaintBrushIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import AppContext from './hooks/createContext';
import axios from 'axios';
import useMaskInpainter from './hooks/useMaskInpainter';
import InpaintHelpModal from './InpaintHelpModal';
import { trackEvent } from 'utils/analytics';

const EditActionPanelComp = ({
  render,
  imgURL,
  editMode,
  setActiveInput,
  subscription,
  setLoaderOverride,
  openPricingModal,
  handleDownload,
  editable,
  inpaintingLoading,
  setInpaintingLoading,
  setDisplayImage,
  handleEditMode,
  handleEditModeChange,
  setOverrideRender
}) => {
  const [statusMessage, setStatusMessage] = useState('');
  const {
    clicks: [clicks, setClicks],
    image: [image, setImage],
    maskImg: [maskImg, setMaskImg],
    maskLockedCanvasImgs: [maskLockedCanvasImgs, setMaskLockedCanvasImgs],
    maskLockedImgs: [maskLockedImgs, setMaskLockedImgs]
  } = useContext(AppContext);

  const [showHelp, setShowHelp] = useState(false);
  const [inpaintingJob, setInpaintingJob] = useState('inpaint');

  const imageInpainter = useMaskInpainter();

  const [callbackUrl, setCallbackUrl] = useState();
  const [readyToSave, setReadyToSave] = useState(false);

  const [photoBucket, setPhotoBucket] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const clearMasks = () => {
    setMaskLockedImgs([]);
    setMaskLockedCanvasImgs([]);
    setMaskImg(null);
  };

  const handleInpaint = async (inpaintingType) => {
    if (!maskImg) {
      setInpaintingJob(inpaintingType);
      setShowHelp(true);
    } else {
      setInpaintingLoading(true);

      const data = await imageInpainter(
        render?.output_url,
        render?.room_type,
        render?.style,
        inpaintingType,
        render?.aspect_ratio
      );

      clearMasks();

      setCallbackUrl(data.urls.get);
    }
  };

  const tapHoldImg = (render, swap) => {
    trackEvent('before_after_tap_hold', {
      event_category: 'engagement',
      event_label: 'before_after_tap_hold'
    });
    if (!render) return;
    if (swap) {
      setDisplayImage(render.output_url);
    } else {
      setDisplayImage(photoBucket[currentImageIndex]);
    }
  };

  const handleNext = async () => {
    if (currentImageIndex === photoBucket.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrev = async () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(photoBucket.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleDiscard = async () => {
    setDisplayImage(render?.output_url);
    clearMasks();
    setReadyToSave(false);
  };

  useEffect(() => {
    if (!photoBucket.length) return;
    setDisplayImage(photoBucket[currentImageIndex]);
  }, [currentImageIndex]);

  useEffect(() => {
    if (!callbackUrl) return;

    const intervalId = setInterval(async () => {
      try {
        console.log('checking status');
        const response = await fetch('/api/instatus/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ callback_url: callbackUrl })
        });

        if (!response.ok) {
          throw new Error('Error fetching inpainting status');
        }

        const {
          data: { status, output }
        } = await response.json();

        if (status === 'success') {
          setLoaderOverride(true);
          setDisplayImage(output.output[0]);
          setPhotoBucket(output.output);
          // setEditMode(false);
          setReadyToSave(true);
          setInpaintingLoading(false);
          clearInterval(intervalId);

          // iterate through the output and set the photo bucket

          for (var i = 1; i < output.output.length; i++) {
            const img = new Image();
            img.src = output.output[i];
          }
        }
      } catch (err) {
        console.error('Error checking inpainting status:', err.message);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [callbackUrl]);

  const handleClear = () => {
    clearMasks();
  };

  const handleSave = async () => {
    // put to api/jobs/{render_id} with imgUrl
    setInpaintingLoading(true);

    // strip out the last segment of the url

    const save_data = {
      save_url: imgURL,
      id: render.id
    };

    const { data, error } = await axios.put(
      `/api/jobs/${render.id}`,
      save_data
    );

    if (error) {
      console.log(error);
      return;
    }

    // console.log(response);

    setDisplayImage(data.data.output_url);
    setOverrideRender(data.data);
    setInpaintingLoading(false);
    setReadyToSave(false);
    handleEditMode(null);
  };

  useEffect(() => {
    if (inpaintingLoading) {
      setStatusMessage('Loading...');
    } else {
      setStatusMessage('Click on the Image');
    }
  }, [inpaintingLoading]);

  return (
    <Transition
      show={editMode}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <InpaintHelpModal
        openModal={showHelp}
        passOpenModal={setShowHelp}
        inpaintingJob={inpaintingJob}
      />

      {/* {editMode && (
        <div className="text-xs font-light text-gray-500">{statusMessage}</div>
      )} */}
      <div
        className={`mt-2 flex px-4 items-center  text-base font-medium text-gray-900 transition-all ${
          editMode ? 'flex-row-reverse justify-end' : 'flex-row justify-between'
        }`}
      >
        <div className="flex flex-row space-x-2 lg:space-x-4 items-center">
          {editable ? (
            <PaintBrushIcon
              className={`w-6 mt-1 ${
                editMode
                  ? inpaintingLoading
                    ? 'w-9 text-indigo-900 rounded-lg border-2 border-indigo-900 p-1 animate-pulse'
                    : 'w-9 text-indigo-900 rounded-lg border-4 border-indigo-900 p-1'
                  : 'w-6 text-indigo-700'
              }`}
              onClick={() => handleEditModeChange(render.id)}
            />
          ) : null}

          {editMode & !inpaintingLoading ? (
            <div>
              {!readyToSave && (
                <span>
                  <button
                    disabled={maskImg === null || inpaintingLoading}
                    className="mt-1 text-xs font-bold text-white bg-rose-400 rounded p-2 md:p-2"
                    onClick={() => handleClear()}
                  >
                    Clear Selections
                  </button>
                </span>
              )}

              {readyToSave && (
                <div>
                  <span className="absolute top-2 right-2 bg-white p-2 rounded-sm shadow-lg">
                    {currentImageIndex + 1}/{photoBucket.length}
                  </span>

                  <div
                    className="bg-none p-4 text-sm absolute z-10 bottom-14 right-0 sm:hidden stop-click"
                    onTouchStart={(event) => {
                      tapHoldImg(render, true);
                      event.stopPropagation();
                    }}
                    onTouchEnd={(event) => {
                      tapHoldImg(render, false);
                      event.stopPropagation();
                    }}
                    onClick={() => null}
                    onContextMenu={(event) => event.preventDefault()}
                  >
                    <div className="bg-white rounded-lg flex align-center items-center opacity-80">
                      {/* <lottie-player
                        src="/tap2.json"
                        style={{
                          width: '45px',
                          pointerEvents: 'none',
                          marginRight: '-10px',
                          marginTop: '-2px'
                        }}
                        background="transparent"
                        speed="0.5"
                        count={3}
                        loop
                        autoplay
                      ></lottie-player> */}
                      <div className="ml-0 mr-2 text-indigo-600 text-xs font-medium">
                        original
                      </div>
                    </div>
                  </div>

                  <button
                    className="justify-self-end mt-1 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300  font-medium rounded-lg text-sm px-4 py-2 text-center "
                    onClick={handleNext}
                  >
                    next
                  </button>
                  <div className="justify-self-end pl-10 flex flex-row gap-2 pr-5">
                    <button
                      // disabled={imgURL !== render?.output_url || inpaintingLoading}
                      className="justify-self-end mt-1 ml-2 text-sm font-bold text-white bg-emerald-500 rounded p-2 md:p-2"
                      onClick={() => handleSave()}
                    >
                      save
                    </button>
                    <button
                      // disabled={imgURL !== render?.output_url || inpaintingLoading}
                      className="mt-1 text-xs font-bold text-white bg-orange-600 rounded p-2 md:p-2"
                      onClick={() => handleDiscard()}
                    >
                      discard
                    </button>
                  </div>
                </div>
              )}

              {/* <div className="flex items-center space-x-2">
                <button onClick={handlePrevImage}>&lt;</button>
                <span>
                  {currentImage}/{imagesCount}
                </span>
                <button onClick={handleNextImage}>&gt;</button>
              </div> */}
            </div>
          ) : null}

          {render?.output_high_url ? (
            <p className="mt-1  text-xs font-bold text-white bg-indigo-800 rounded p-2">
              HD
            </p>
          ) : null}

          {setActiveInput && !editMode ? (
            <ArrowPathRoundedSquareIcon
              className="w-6 text-indigo-700 mt-1"
              onClick={() => setActiveInput(imgURL, render?.aspect_ratio)}
            />
          ) : null}
          {subscription && !editMode ? (
            <ArrowDownIcon
              onClick={(e) => handleDownload(e, render, imgURL)}
              className="w-6 text-indigo-700 mt-1"
            />
          ) : (
            !editMode && (
              <ArrowDownIcon
                className="w-6 text-indigo-700 mt-1"
                onClick={() => {
                  openPricingModal();
                }}
              />
            )
          )}
        </div>
      </div>
      <p className="mt-1 px-5 text-sm text-gray-500">{render?.redesignType}</p>
    </Transition>
  );
};

const EditActionPanel = memo(EditActionPanelComp);

export default EditActionPanel;
