import { useState, useEffect, memo } from 'react';
import NextImage from 'next/image';
import { useWindowSize } from './useWindowSize';

const aspectToConfig = {
  square: 1,
  phone: 1.77,
  landscape: 0.56,
  landscape_small: 512 / 768,
  tall_square: 1.33
};

const CustomImageComp = ({
  src,
  altSrc,
  alt,
  aspectRatio = 'square',
  loaderOverride,
  placeholder,
  className,
  quality,
  priority,
  widths,
  isPriority,
  hideWatermark = true
}) => {
  const activeAspectRatio = aspectRatio || 'square';

  const windowSize = useWindowSize();

  const supported_configs_low = {
    square: { width: 768, height: 768 },
    phone: { width: 512, height: 912 },
    landscape_small: { width: 768, height: 512 },
    landscape: { width: 912, height: 512 },
    tall_square: { width: 576, height: 768 }
  };

  const starterHeight = supported_configs_low[activeAspectRatio].height;
  const starterWidth = supported_configs_low[activeAspectRatio].width;

  const [dimensions, setDimensions] = useState({
    width: starterWidth,
    height: starterHeight
  });

  const [override, setOverride] = useState(loaderOverride);

  // useEffect(() => {
  //   if (windowSize.screenSize && widths) {
  //     const screenIndex = {
  //       sm: 0,
  //       md: 1,
  //       lg: 2
  //     };
  //     var width = widths[screenIndex[windowSize.screenSize]];
  //     var height = Math.round(width / aspectToConfig[activeAspectRatio]);

  //     if (width > height) {
  //       height = Math.max(height, widths[0]);
  //       width = Math.round(height * aspectToConfig[activeAspectRatio]);
  //     } else {
  //       width = Math.max(width, widths[0]);
  //       height = Math.round(width / aspectToConfig[activeAspectRatio]);
  //     }

  //     setDimensions({ width, height });
  //   }
  // }, [windowSize, widths, activeAspectRatio, aspectToConfig]);

  if (!dimensions.width || !dimensions.height) {
    return null;
  }

  const cloudflareLoader = ({ src, width, height, quality }) => {
    if (override) {
      return src;
    }
    const params = [`width=${parseInt(width)}`, `height=${parseInt(height)}`];
    if (quality) {
      params.push(`quality=${parseInt(quality)}`);
    }
    const paramsString = params.join(',');

    var new_url = src.replace(/\/[^\/]*$/, `/${paramsString}`);

    return new_url;
  };

  const preLoadBefore = (r) => {
    if (altSrc) {
      const img = new Image();

      const nsrc = cloudflareLoader({
        src: altSrc,
        width: dimensions.width,
        height: dimensions.height
      });

      img.src = nsrc;
    }
  };

  const handleImageError = (e) => {
    setOverride(true);
    setHasError(true);
    // setImgSrc(fallbackSrc);
  };

  const [hasError, setHasError] = useState(false);
  // const [showWatermark, setHasWaterMark] = useState(true);

  const WatermarkSVG = () => (
    <svg
      width="100%"
      height="80%"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute z-10  "
      style={{ opacity: 1 }}
    >
      <text
        transform="translate(20, 100) rotate(-45)"
        fill="rgba(45,45,45,0.08)"
        font-size="18"
      >
        sofabrain.com
      </text>
      {/* <text x="10" y="25" fontFamily="Arial" fontSize="50" fill="white" >
        sofabrain.com
      </text> */}
    </svg>
  );

  const markTemplate = (name) => {
    return `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='120px' width='280px'><text transform='translate(20, 100) rotate(0)' fill='rgba(255, 255,255, 0.7)' font-family='Arial, sans-serif' font-size='40'>${name}</text></svg>`;
  };

  const watermarkStyle = hideWatermark
    ? {}
    : {
        backgroundImage: `url("data:image/svg+xml;base64,${btoa(
          markTemplate('sofabrain.com')
        )}")`,
        backgroundPosition: 'right 12% top -50px',
        backgroundRepeat: 'no-repeat'
        // Other styles for positioning and size of the watermark can be added here
      };

  return (
    <div className="relative">
      <div
        className="overlay z-10 stop-click"
        style={watermarkStyle}
        onTouchStart={(event) => {
          // tapHoldImg(render, true);
          event.stopPropagation();
        }}
        onTouchEnd={(event) => {
          // tapHoldImg(render, false);
          event.stopPropagation();
        }}
        onContextMenu={(event) => event.preventDefault()}
      ></div>

      {/* {showWatermark && <WatermarkSVG />} */}

      <NextImage
        src={src}
        alt={alt}
        key={src}
        width={hasError ? '200' : dimensions.width}
        height={hasError ? '200' : dimensions.height}
        priority={true}
        className={className + ' relative stop-click max-w-full'}
        onLoad={(r) => preLoadBefore(r)}
        onError={(e) => handleImageError()}
        style={{
          background: '#E0EAFC',
          background: '-webkit-linear-gradient(to right, #E0EAFC, #CFDEF3)',
          background: 'linear-gradient(to right, #E0EAFC, #CFDEF3)'
        }}
        loader={() =>
          cloudflareLoader({
            src,
            width: dimensions.width,
            height: dimensions.height,
            quality
          })
        }
        quality={quality || 95}
        draggable="false"
      />
    </div>
  );
};

const CustomImage = memo(CustomImageComp);

export default CustomImage;
