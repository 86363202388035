import { useState } from 'react';

const useFileUpload = () => {
  const uploadFile = async (file) => {
    const randomString = Math.random().toString(36).substring(2, 20);
    const randomFileName = `${randomString}-${file.name}`;

    const response = await fetch('/api/r2/uploadFile', {
      method: 'POST',
      body: JSON.stringify({
        name: randomFileName,
        type: file.type
      })
    });

    const { url, id, error } = await response.json();

    if (error) throw Error(error.message);

    const formData = new FormData();
    formData.append('file', file);

    await fetch(url, {
      method: 'post',
      body: formData
    });

    const urlWithoutQuery = `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/public`;
    return urlWithoutQuery;
  };

  return { uploadFile };
};

export default useFileUpload;
