import { trackEvent } from 'utils/analytics';
import axios from 'axios';
const TimeDifferenceDisplay = (current, previous, imgTime, setImgTime) => {
  current = new Date(current);
  previous = new Date(previous);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const elapsed = current - previous;
  if (elapsed < msPerMinute) {
    if (Math.round(elapsed / 1000) < 2) {
      if (!imgTime) {
        let time = Math.floor(Math.random() * (21 - 8 + 1) + 8) + ' seconds';
        setImgTime(time);
        return time;
      }
      return imgTime;
    }
    return Math.round(elapsed / 1000) + ' seconds';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes';
  } else {
    return Math.round(elapsed / msPerHour) + ' hours';
  }
};

const handleDownload = async (e, render, imgURL) => {
  // axios get with accept header that only accepts png
  // and then download the file
  trackEvent('download_image', {
    event_category: 'engagement',
    event_label: 'download_image'
  });

  let downloadable_url = render?.output_high_url || render?.output_url;

  if (imgURL) {
    // if the image is being displayed, use that url instead

    downloadable_url = imgURL;
  }

  // replace string after the last / with "full"

  const parsed_url = downloadable_url.replace(/[^/]*$/, 'full');

  const { data, error } = await axios.get(parsed_url, {
    responseType: 'blob',
    headers: {
      Accept: 'image/png'
    }
  });

  const url = URL.createObjectURL(data, { type: 'image/png' });

  const link = document.createElement('a');

  // set the href to the blob url

  link.download = `render-${render.id}.png`;

  link.href = url;

  const startDownload = (link) => {
    // triggers the download of the unmounted link element

    link.click();
    link.remove();
  };

  startDownload(link);
};

export { TimeDifferenceDisplay, handleDownload };
