import { useContext } from 'react';
import AppContext from './createContext';
import useFileUpload from './useFileUpload';
import { has } from 'underscore';
function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This might be necessary depending on the CORS settings of the image's host
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = url;
  });
}
const createWhiteMaskImage = (maskCanvas) => {
  const canvasWhite = document.createElement('canvas');
  const canvasBlack = document.createElement('canvas');
  canvasWhite.width = canvasBlack.width = maskCanvas.width;
  canvasWhite.height = canvasBlack.height = maskCanvas.height;
  const ctxWhite = canvasWhite.getContext('2d');
  const ctxBlack = canvasBlack.getContext('2d');
  if (!ctxWhite || !ctxBlack)
    throw new Error('Failed to create canvas context');

  ctxWhite.drawImage(maskCanvas, 0, 0, maskCanvas.width, maskCanvas.height);
  ctxBlack.fillStyle = 'rgba(0,0,0,0)'; // Start with a transparent background for the black mask
  ctxBlack.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

  const imgDataWhite = ctxWhite.getImageData(
    0,
    0,
    maskCanvas.width,
    maskCanvas.height
  );
  const imgDataBlack = ctxBlack.createImageData(
    maskCanvas.width,
    maskCanvas.height
  ); // Create a new blank ImageData
  const dataWhite = imgDataWhite.data;
  const dataBlack = imgDataBlack.data;
  var hasChanged = false;
  for (let i = 0; i < dataWhite.length; i += 4) {
    const isWhite =
      dataWhite[i] === 255 &&
      dataWhite[i + 1] === 255 &&
      dataWhite[i + 2] === 255;
    const isBlack =
      dataWhite[i] === 0 && dataWhite[i + 1] === 0 && dataWhite[i + 2] === 0;
    // For non-transparent pixels
    if (dataWhite[i + 3] > 0) {
      dataWhite[i] = 255; // red
      dataWhite[i + 1] = 255; // green
      dataWhite[i + 2] = 255; // blue
    } else {
      dataWhite[i] = 0; // red
      dataWhite[i + 1] = 0; // green
      dataWhite[i + 2] = 0; // blue
      dataWhite[i + 3] = 255;
    }
    // Specifically for originally black pixels
    if (!isWhite && !isBlack) {
      hasChanged = true;

      dataBlack[i] = 23; // red
      dataBlack[i + 1] = 50;
      dataBlack[i + 2] = 255;
      dataBlack[i + 3] = 255; // alpha, making black pixels opaque
    } else {
      // Make non-black pixels fully transparent
      dataBlack[i + 3] = 0; // alpha
    }
  }

  imgDataBlack.data.set(dataBlack);

  ctxWhite.putImageData(imgDataWhite, 0, 0);
  ctxBlack.putImageData(imgDataBlack, 0, 0);

  return {
    whiteMaskCanvas: canvasWhite,
    blackMaskCanvas: canvasBlack,
    hasChanged
  };
};

const useMaskInpainter = () => {
  const {
    image: [image],
    maskLockedImgs: [maskLockedImgs]
  } = useContext(AppContext);

  const { uploadFile } = useFileUpload();

  const callInpaintingAPI = async (
    imageUrl,
    maskUrl,
    room_type,
    style,
    inpaintingType,
    prompt = null,
    aspect_ratio = null,
    brush_type = 'create',
    selectedCustomStyle,
    selectedMyStyle,
    styleType,
    quickStyles,
    scribble_mask_url
  ) => {
    try {
      var activeStyle = style;
      var url_without_query_quick = '';

      var activePrompt = prompt;

      if (quickStyles.length > 0) {
        url_without_query_quick = quickStyles;
        activePrompt = '';
      } else if (prompt !== null) {
        url_without_query_quick = '';
      } else if (styleType === 'my styles') {
        url_without_query_quick =
          selectedMyStyle['inspiration_images'].join('|');
        activeStyle = selectedMyStyle.public_name;
      } else if (styleType === 'community') {
        url_without_query_quick =
          selectedCustomStyle['inspiration_images'].join('|');
        activeStyle = selectedCustomStyle.public_name;
      }
      const neutralizeStyle = styleType != 'classical';

      const response = await fetch('/api/jobs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input_source_url: imageUrl,
          mask_url: maskUrl,
          room_type: room_type,
          style: activeStyle,
          render_type: 'inpainting',
          inpainting_type: inpaintingType,
          prompt: activePrompt,
          aspect_ratio: aspect_ratio,
          brush_type: brush_type,
          inspiration_image: url_without_query_quick,
          neutralize_style: neutralizeStyle,
          scribble_mask_url: scribble_mask_url

          // Add any additional necessary parameters here
        })
      });

      const result = await response.json();

      return result;
    } catch (error) {
      console.error('Error calling inpainting API:', error.message);
      throw error;
    }
  };

  async function loadImageAndDraw(url, targetCanvas) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Necessary for loading cross-origin images to avoid security errors
      img.onload = () => {
        const ctx = targetCanvas.getContext('2d');
        targetCanvas.width = img.width; // Adjust canvas size to match image dimensions
        targetCanvas.height = img.height;
        ctx.drawImage(img, 0, 0); // Draw the image onto the canvas
        resolve(); // Image has been successfully loaded and drawn
      };
      img.onerror = () => reject(new Error('Failed to load image'));
      img.src = url; // Set the source URL of the image
    });
  }

  function downloadCanvas(canvas, filename) {
    // Create an anchor (<a>) element
    const link = document.createElement('a');
    link.download = filename;

    // Convert the canvas to a data URL and set it as the href of the link
    link.href = canvas.toDataURL('image/png');

    // Trigger the download by simulating a click on the link
    link.click();
  }

  const uploadAndProcessImage = async (
    input_url,
    room_type,
    style,
    inpaintingType,
    prompt = null,
    aspect_ratio = null,
    brush_type = 'create',
    selectedCustomStyle,
    selectedMyStyle,
    styleType,
    fileQuick,
    imageUrlQuick,
    useQuickStyles
  ) => {
    if (!image) return;
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, image.width, image.height);

    const accumulatedMask = maskLockedImgs.reduce(
      (acc, maskImg) => {
        if (maskImg) {
          acc
            .getContext('2d')
            .drawImage(maskImg, 0, 0, image.width, image.height);
        }
        return acc;
      },
      (() => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        return canvas;
      })()
    );

    const { whiteMaskCanvas, blackMaskCanvas, hasChanged } =
      createWhiteMaskImage(accumulatedMask);

    ctx.drawImage(whiteMaskCanvas, 0, 0, image.width, image.height);

    try {
      const canvasToBlob = async (canvas, mimeType) => {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error('Failed to create blob from canvas'));
            } else {
              resolve(blob);
            }
          }, mimeType);
        });
      };

      const maskBlob = await canvasToBlob(whiteMaskCanvas, 'image/png');
      const maskFile = new File([maskBlob], 'mask.png', { type: 'image/png' });
      const maskUrl = await uploadFile(maskFile);

      // handle black mask
      if (hasChanged && inpaintingType !== 'erase') {
        const canvasImage = document.createElement('canvas');
        canvasImage.width = image.width;
        canvasImage.height = image.height;
        const ctxImage = canvas.getContext('2d');
        if (!ctxImage) return;

        // await loadImageAndDraw(input_url, canvasImage);

        function overlayBlackMaskOntoTarget(blackMaskCanvas, targetCanvas) {
          const ctxTarget = targetCanvas.getContext('2d');
          const ctxBlackMask = blackMaskCanvas.getContext('2d');

          const targetImageData = ctxTarget.getImageData(
            0,
            0,
            targetCanvas.width,
            targetCanvas.height
          );
          const blackMaskImageData = ctxBlackMask.getImageData(
            0,
            0,
            blackMaskCanvas.width,
            blackMaskCanvas.height
          );

          const dataTarget = targetImageData.data;
          const dataBlackMask = blackMaskImageData.data;

          for (let i = 0; i < dataBlackMask.length; i += 4) {
            if (dataBlackMask[i + 3] > 0) {
              // Non-transparent pixel
              dataTarget[i] = dataBlackMask[i];
              dataTarget[i + 1] = dataBlackMask[i + 1];
              dataTarget[i + 2] = dataBlackMask[i + 2];
              dataTarget[i + 3] = dataBlackMask[i + 3];
            }
          }

          ctxTarget.putImageData(targetImageData, 0, 0);
        }

        overlayBlackMaskOntoTarget(blackMaskCanvas, canvasImage);

        // downloadCanvas(canvasImage, 'updated_image.png');
        // downloadCanvas(whiteMaskCanvas, 'wmask.png');
        // downloadCanvas(blackMaskCanvas, 'bmask.png');

        // ctxImage.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctxImage.drawImage(blackMaskCanvas, 0, 0);

        const updatedImageBlob = await canvasToBlob(canvasImage, 'image/png');
        const updatedImageFile = new File(
          [updatedImageBlob],
          'updated_image.png',
          { type: 'image/png' }
        );
        var scribble_mask_url = await uploadFile(updatedImageFile);
        var updatedBrushType = 'renders';
      } else {
        var scribble_mask_url = null;
        var updatedBrushType = brush_type;
      }

      var url_without_query_quick = '';

      if (useQuickStyles) {
        var uploadedUrls = [];

        for (let i = 0; i < fileQuick.length; i++) {
          const file = fileQuick[i];

          if (!file) {
            setErrMsg(true);
          } else {
            const randomString = Math.random().toString(36).substring(2, 20);

            // create file name
            const randomFileName = `${randomString}-${file.name}`;

            const response = await fetch('/api/r2/uploadFile', {
              method: 'POST',
              body: JSON.stringify({
                name: randomFileName,
                type: file.type
              })
            });

            const { url, id, error } = await response.json();

            if (error) throw Error(error.message);

            // Now we do the actual upload

            const formData = new FormData();

            formData.append('file', file);

            const upload = await fetch(url, {
              method: 'post',
              body: formData
            });

            // url_without_query_quick += `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/public`;

            uploadedUrls.push(
              `https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/${id}/public`
            );
          }

          url_without_query_quick = uploadedUrls.join('|');
        }
      }

      const { data } = await callInpaintingAPI(
        input_url,
        maskUrl,
        room_type,
        style,
        inpaintingType,
        prompt,
        aspect_ratio,
        updatedBrushType,
        selectedCustomStyle,
        selectedMyStyle,
        styleType,
        url_without_query_quick,
        scribble_mask_url
      );

      return data;
    } catch (error) {
      console.error('Error uploading and processing image:', error.message);
    }
  };

  return uploadAndProcessImage;
};

export default useMaskInpainter;
