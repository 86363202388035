import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import Share from './ShareButton';
import { getURL } from '@/utils/helpers';

import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ItemMenu({
  render,
  setOverrideRender,
  setDisplayImage
}) {
  const [upscaling, setUpScaling] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/api/jobs/${render.id}`);
      if (response.status === 200) {
        console.log('Deleted successfully');

        // reload page

        window.location.reload();

        // You can also add some logic here to remove the item from the UI or inform the user.
      }
    } catch (error) {
      alert('Error deleting item');

      console.error('Error deleting item:', error);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const polling_logic = async (callbackUrl) => {
    let output_url;

    while (true) {
      try {
        const response = await fetch('/api/upstatus/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ callback_url: callbackUrl, id: render.id })
        });

        if (!response.ok) {
          throw new Error('Error fetching inpainting status');
        }

        var data = await response.json();

        var { status, output, job } = data;

        if (status === 'success') {
          //   output_url = output.output;
          break;
        }

        if (status === 'failed') {
          throw new Error('Error Upscaling');
        }
      } catch (err) {
        console.error('Error checking inpainting status:', err.message);
      }

      await delay(2000);
    }

    return job;
  };
  //

  const handleUpscale = async () => {
    console.log('upscaling started');
    setUpScaling(true);

    var activeUrl = render.output_url;

    const supported_configs_high = {
      square: { width: 768, height: 768 },
      phone: { width: 576, height: 1024 },
      landscape_small: { width: 768, height: 512 },
      landscape: { width: 1024, height: 576 },
      tall_square: { width: 576, height: 768 }
    };

    const { width, height } = supported_configs_high[render.aspect_ratio];

    function updateUrlWithNewDimensions(url, width, height) {
      // Remove the last segment of the URL (anything after the last '/')
      let baseUrl = url.replace(/\/[^\/]*$/, '');

      // Append new dimension parameters
      let newUrl = `${baseUrl}/h=${Math.floor(height / 1)},w=${Math.floor(
        width / 1.0
      )}`;

      return newUrl;
    }

    // let activeUrl = `${url.replace('/free', '/')}h=${height}&w=${width}`;
    activeUrl = updateUrlWithNewDimensions(activeUrl, width, height);

    const res = await axios.post('/api/upscale', {
      image_url: activeUrl
    });

    const updatedRender = await polling_logic(res.data.urls.get);

    setOverrideRender(updatedRender);
    setDisplayImage(updatedRender.output_high_url);
    setUpScaling(false);
  };

  //   if render has the key render quality then show, if not hide (just to make a distiction between public and private feed)

  const hide = render.quality ? '' : 'hidden';

  return (
    <Menu as="div" className={`relative ml-auto ${hide}`}>
      {showConfirmModal && (
        <div className="fixed z-50 inset-0 bg-black/50 flex items-center justify-center">
          <div className="bg-white rounded p-5">
            <p>Are you sure you want to delete this item?</p>
            <div className="flex flex-row gap-10 justify-between mt-10">
              <button
                className="bg-red-600 text-white px-2 py-2 "
                onClick={handleDelete}
              >
                Yes, delete it
              </button>
              <button
                className="bg-blue-600 text-white px-2 py-2"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`bg-white absolute right-0 p-2 rounded-lg text-center flex flex-row justify-center items-center  text-white gap-2 ${
          upscaling ? 'block' : 'hidden'
        }`}
      >
        <span className="gradient-text">Upscaling </span>

        <span className="text-[6px] whitespace-nowrap animate-pulse bg-pink-500 text-white  rounded-lg px-2 py-0 ">
          30 to 90s
        </span>
      </div>
      <Menu.Button
        className={`-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500  ${
          upscaling ? 'hidden' : 'block'
        }`}
      >
        <span className="sr-only">Open options</span>
        <EllipsisHorizontalIcon
          className="h-7 w-7 text-white   "
          aria-hidden="true"
        />
      </Menu.Button>
      <Transition
        // as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={handleUpscale}
                className={classNames(
                  active ? 'bg-gray-50' : '',
                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                )}
              >
                Upscale
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Share
                label="share"
                className={classNames(
                  active ? 'bg-gray-500' : '',
                  'block px-3 py-2 text-sm leading-6 text-gray-900'
                )}
                simple={true}
                url={`${getURL()}share/${render?.id}`}
                text={`${getURL()}share/${render.id}`}
                title={`${getURL()}share/${render.id}`}
              />
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={() => setShowConfirmModal(true)}
                className={classNames(
                  active ? 'bg-gray-50' : '',
                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                )}
              >
                Delete
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

// export default ItemMenu;
