import classNames from 'classnames';
import { useState, useEffect } from 'react';

import { trackEvent } from 'utils/analytics';

import RedesignedRoom from './RedesignedRoom';

export default function RenderItem({
  render,
  preview,
  passOpenModal,
  subscription,
  setActiveInput,
  addLoadingPercentage,
  HDImage,
  editable,
  gridMode = false,
  handleEditMode,
  editableRender,
  hijackAdvancedEditMode,
  isPriority = false
}) {
  const openPricingModal = () => {
    trackEvent('price_alert_gallery', {
      event_category: 'engagement',
      event_label: 'price_alert_gallery'
    });

    passOpenModal(true);
  };
  const tapHoldImg = (render, swap) => {
    trackEvent('before_after_tap_hold', {
      event_category: 'engagement',
      event_label: 'before_after_tap_hold'
    });
    if (!render) return;
    if (swap) {
      setImgURL(render.input_source_url);
    } else {
      if (render.output_high_url) setImgURL(render.output_high_url);
      else if (render.output_url) setImgURL(render.output_url);
    }
  };

  const [counter, setCounter] = useState(0);
  const [imgURL, setImgURL] = useState(render.input_source_url);
  const [overrideRender, setOverrideRender] = useState(render);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(render?.id === editableRender);
  }, [editableRender]);

  useEffect(() => {
    if (render.output_url) {
      setOverrideRender(render);
    }
  }, [render]);

  const counterValid = counter < 200;

  useEffect(() => {
    if (addLoadingPercentage) {
      let intervalLength = 250;
      if (HDImage === 'high') {
        intervalLength = 320;
      }
      const intervalId =
        counterValid &&
        setInterval(() => setCounter((t) => t + 1), intervalLength);
      return () => clearInterval(intervalId);
    }
  }, [counterValid]);

  useEffect(() => {
    if (!render) setImgURL(null);
    else if (render.output_high_url) setImgURL(render.output_high_url);
    else if (render.output_url) setImgURL(render.output_url);
    else setImgURL(render.input_source_url);
  }, [render]);

  // const [preloadedSrc, setPreloadedSrc] = useState(null);
  // const [isVisible, setIsVisible] = useState(false);
  // const containerRef = useRef(null);

  return (
    <div key={render?.id} className="self-stretch ">
      <div className={classNames(preview ? 'mt-0' : 'mt-6')}>
        {/* <OriginalRoom
          setActiveInput={setActiveInput}
          render={render}
          gridMode={gridMode}
          isPriority={isPriority}
        /> */}
        <RedesignedRoom
          setActiveInput={setActiveInput}
          render={overrideRender}
          setOverrideRender={setOverrideRender}
          subscription={subscription}
          openPricingModal={openPricingModal}
          tapHoldImg={tapHoldImg}
          imgURL={imgURL}
          setDisplayImage={setImgURL}
          editable={editable}
          editMode={editMode}
          handleEditMode={handleEditMode}
          editableRender={editableRender}
          counter={counter}
          isPriority={isPriority}
          hijackAdvancedEditMode={hijackAdvancedEditMode}
          className="w-full"
        />
      </div>
    </div>
  );
}
