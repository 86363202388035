import { useState, useEffect } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    screenSize: undefined
  });

  useEffect(() => {
    const handleResize = () => {
      let screenSize;

      if (window.innerWidth <= 640) {
        screenSize = 'sm';
      } else if (window.innerWidth <= 1024) {
        screenSize = 'md';
      } else {
        screenSize = 'lg';
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        screenSize
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
